*
{
    margin: 0;
    padding: 0;
    
}

html,
body
{   
    /* overflow: hidden; */
    /* Overflow: visible; */
    -webkit-overflow-scrolling: auto !important;
    cursor: url('/static/mcircle.png'),auto;
}








.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    /* mix-blend-mode: multiply; */
}


.container{
    height: 88%;
    width: 100%;
    display: grid;
    place-items: center;
}
  
h1{
    
    font-size: 4rem;
    text-transform: uppercase;
    color: black;
}

.titulo{
    left: 0%;
    padding: 0;
    padding-top: 5px;
    font-family: 'Lato', sans-serif;
    width: 100%;
    display: block;
    place-items: top;
    font-size: 3.0vw;
    text-transform: uppercase;
    color: white;
    position: absolute;
   top: -1%;
   z-index: 3;
   -webkit-transform: translate3d(0,0,3px);
    transform: translate3d(0,0,3px);
    font-weight: 300;
}


.backimage{
    background-color: #000;
    width: 100%;
    height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    
    display: none;
    -webkit-transform: translate3d(0,0,21px);
    transform: translate3d(0,0,21px);
    z-index: 21;
}

#gira{
  background-image: url('/static/esferagif.gif');
  background-size: cover;
}

#centerimage {
    background-color: #000;
    display:none;
    position: relative;
   /*  left:0;
    right:0;
    top: 35%;
    bottom:35%; */
   /*  margin:auto; */
    content: url('/static/textures/índice.png');
    
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;

    -webkit-transform: translate3d(0,0,22px);
    transform: translate3d(0,0,22px);
    z-index: 22;
    
  }
  
  .fade{
    opacity: 1;
    transition: 3s;
}

  .textcenter{
    position: absolute;
    display: block;
    font-family: 'Lato', sans-serif;
    color: white;
    /* width: 50%;
    min-height: 300px; */
    width: 100%;
    height: 0%;
    /* padding: 5px; */
    /* display: flex; */
    justify-content: center;
    align-items: center;
    font-size: 4vw;
    /* position the div in center */
    position: absolute;
    top: 35%;
    text-align: center;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    font-weight: 300;
    text-transform: uppercase;
  }


#ok{

  border: 2px solid white;
  border-radius: 10px;
  font-size: 10px;
  padding: 10px;

  margin-left: 10px;

  color: white;
  margin-top: 17px;

  

}

#ok:hover{
  background-color: rgba(255, 255, 255, 0.522);
  color: #000;
}

#botonok{
  margin: auto;
  width: 50%;
}

  .tarjeta {
    display: none;
    font-family: 'Lato', sans-serif;
    width: 20%;
    /* height: 100px; */

    max-height: 75%;
    
    
    line-height: 1.28;
    justify-content: center;
    align-items: center;
    position: absolute;
    /* font-weight: 400; */
    top: 35%;
    left: 50%;
    
    box-shadow: 5px 5px 5px black;
    padding: 27px;
    font-size: 0.7rem;
    
    min-width: 300px;
    
    
    
    font-weight: 600;
    overflow: auto;

    padding-top: 35px;


    z-index: 21;
      -webkit-transform: translate3d(0,0,21px);
    transform: translate3d(0,0,21px);


    
    transform: translate3d(0,0,5px) translate(-50%, -50%);

  }


  /* .tarjeta-overflow {
    min-height: 500px;
  } */
  
  .tarjeta::-webkit-scrollbar-track {
    
    padding: 2px 0;
    background-color: #242424c5;
  }
  
  .tarjeta::-webkit-scrollbar {
    width: 10px;
  }
  
  .tarjeta::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: aliceblue;
    border: 1px solid rgb(232, 232, 232);
  }








#cerrartarjeta{
  position: absolute;
  text-align: right;
  right: 8px;
  top: 8px;
}

#cerrartarjeta:hover{

  cursor: pointer;

}

  #centervideo {
    width:100%;
    display:none;
    position:absolute;
  
    margin:auto;
    content: url('/static/textures/índice.png');
    max-width: 100%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
    -webkit-transform: translate3d(0,0,22px);
    transform: translate3d(0,0,22px);
    z-index: 22;
  }
  





  #closeimage {
    
    display:none;
    position:absolute;
    margin:auto;
    
    
    
    

    margin:auto;
    content: url('/src/images/close.png');

    width: 5%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;
    z-index: 25;
    -webkit-transform: translate3d(0,0,25px);
    transform: translate3d(0,0,25px);
    z-index: 40;
    left: 93%;
    
    
  }

  #closeimage:hover {
    
    cursor: pointer;
    
  }


  


  
  /* ------------- */
  
  .barra{
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
  
  }


  .dropdown-btn{
    
    /* margin-top: 1px; */
    position: relative;
    /* background-color: #000; */
    /* color: white; */
    background-color: rgba(255, 255, 255, 0);;
    
    font-size: 16px;
    
    height: 40px;
    padding-left: 4px;
    padding-right: 4px;
    border: none;
    cursor: pointer;

    transition: 0.3s;

    /* top:  -5px; */
    
    
    
    
}

.dropdown {
    float: right;
    position: relative;
    top: 10%;
    
    z-index:10;
    -webkit-transform: translate3d(0,0,10px);
    transform: translate3d(0,0,10px);   
    right: 100% - 40px; 
}



.dropdown-content{
    display: none;
    position: fixed;
    right: 1px;
    /* float: left; */
    /* left: 50px; */
    /* referente al padre que este tiene */
    background-color: black;
    min-width: 200px;
    max-height: 0;
    overflow: hidden;
    /* border-bottom-right-radius: 25px;  */  
    -webkit-transition: max-height 0.2s ease-in;
    transition: max-height 0.2s ease-in;
    text-align: center;
    

}

.dropdown-content a{
   /*  vertical y el otro horizontal */
    padding: 12px 16px;
    color: white;
    text-decoration: none;
    display: block;
    font-weight: 900;
    
}

.dropdown-content a:hover {
    background-color: #f1f1f1;
    color: black;
    
    
    
}

/* cuando estemos sobre la lista. pero solo sobre el boton */
.dropdown:hover .dropdown-btn {
    /* background-color: #000; */
    transform: scale(1.1, 1.1);
}

.dropdown:hover .barra {
    background-color: white;
    
}

/* lo que hace aca que cuando estoy sobre dropdown, ala propiedad de .dropdown-content la cambio */
.dropdown:hover .dropdown-content {
    display: block;
    max-height: 1000px;
}

.derecha{
    font-family: 'Lato', sans-serif;
}

/* .barra:hover .barra{
    background-color: white;
} */



#imagen360 {
    
    display:block;
    position: relative;
   /*  left:0;
    right:0;
    top: 35%;
    bottom:35%; */
   /*  margin:auto; */
    content: url('images/360.png');
    
    max-width: 20%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    top: 0;
    -o-object-fit: contain;
    object-fit: contain;


    
  }

  #hand {
    
    display:block;
    position: relative;
   /*  left:0;
    right:0;
    top: 35%;
    bottom:35%; */
   /*  margin:auto; */
    content: url('images/hand.png');
    top: 45px;
    
    max-width: 13%;
    max-height: 100%;
    bottom: 0;
    left: 0;
    margin: auto;
    overflow: auto;
    position: fixed;
    right: 0;
    
    -o-object-fit: contain;
    object-fit: contain;


    animation-name: example;
  animation-duration: 4s;
  animation-iteration-count: infinite;


    
  }


  @keyframes example {
    0%   {left:-100px; top:60px;}
    50%  { left:100px; top:60px;}
   
    100%  {left:-100px; top:60px;}
    
    
  }

  #hideme {
    display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      
  }

  #botonaudio{
      z-index: 20;
      -webkit-transform: translate3d(0,0,20px);
    transform: translate3d(0,0,20px);

    position: absolute;
    bottom: 1%;
    left: 1%;
  } 

  #botonaudio:hover{
    cursor: pointer;
  }

  #activaraudio{
   
    font-family: 'Lato', sans-serif;
    
    display: block;
 
    font-size: 1.2rem;
    /* text-transform: uppercase; */
    color: white;
    position: absolute;
  
   z-index: 1;
   -webkit-transform: translate3d(0,0,3px);
    transform: translate3d(0,0,3px);

    bottom: 33px;
    left: 90px;
    font-weight: 600;
}


.link{
  font-family: 'Lato', sans-serif;
}

